import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import newsletter from "./../util/newsletter.js";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: "#ffffff",
      display: "block",
      textAlign: "center",
      lineHeight: "50%",
      fontSize: ".85em",
      paddingLeft: "10px"
    }
  }
}));


function Newsletter(props) {
  const [subscribed, setSubscribed] = useState(false);
  const classes = useStyles();

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <>
      {subscribed === false && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={true}>
              <TextField
                type="email"
                label="Type your email here"
                name="email"
                error={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                fullWidth={true}
                InputProps={{ disableUnderline: true, buttonColor: '#fffff' }}
                // inputProps={{ disableUnderline: true, buttonColor: '#fffff  ' }}
                style={{ backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: 25 }}
                // style={{ color: '#ffffff' }}
                className={classes.root}

                inputRef={register({
                  required: "Please enter an email address",
                })}
              />
            </Grid>
            <Box display="flex" alignItems="stretch" clone={true}>
              <Grid item={true} xs="auto">
                <Button
                  variant="contained"
                  color={props.buttonColor}
                  size="large"
                  type="submit"
                >
                  {props.buttonText}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </form>
      )}

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default Newsletter;

import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import { Link } from "./../util/router.js";
import Typography from "@material-ui/core/Typography";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import useDarkMode from "use-dark-mode";
import { makeStyles } from "@material-ui/core/styles";
import companyLogo from "../assets/tringam_white_bg.svg";

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: "auto",
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    display: "flex",
    flex: "none",
    justifyContent: "center",
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.up("sm")]: {
      flex: "50%",
    },
  },
  brand: {
    display: "block",
    height: 32,
  },
  social: {
    alignItems: "flex-end",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    lineHeight: 1,
    "&:hover": {
      opacity: 0.8,
    },
    "&:not(:first-of-type)": {
      marginLeft: 20,
    },
  },
  left: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  right: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  // Move links to end (bottom right)
  // Swaps position with social
  smallLinks: {
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
  },
}));

function Footer(props) {
  const classes = useStyles();

  const darkMode = useDarkMode();
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  return (
    <Section
      bgColor={props.bgColor}
      color="paper"
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <div className={classes.wrapper}>
          <div className={`${classes.item} ${classes.left}`}>
            {/* <Link to="/"> */}
              {/* <img src={logo} alt="Logo" className={classes.brand} /> */}
              <img height="35px" src={companyLogo} alt="Logo"  />
              {/* <h1 style={{textDecoration: 'none', color: '#391484', paddingTop: '15px'}}>Tringam</h1> */}
            {/* </Link> */}
            {/* <a href="/" style={{textDecoration: 'none', color: '#ffffff'}}><h1>Tringam</h1></a> */}

          </div>
          <div
            className={`${classes.item} ${classes.right} ${classes.smallLinks}`}
          >
            <Typography>
              <Link to="/about" className={classes.link}>
                About
              </Link>
              <Link to="/careers" className={classes.link}>
                Careers
              </Link>
              <Link to="/contact" className={classes.link}>
                Contact
              </Link>
              <a
                href="https://medium.com"
                target="_blank"
                className={classes.link}
              >
                Blog
              </a>
            </Typography>
          </div>
          <div className={`${classes.item} ${classes.right} ${classes.social}`}>
            <a
              href="https://twitter.com/"
              target="_blank"
              className={classes.link}
            >
              <TwitterIcon fontSize="small" />
            </a>
            <a
              href="https://facebook.com/"
              target="_blank"
              className={classes.link}
            >
              <FacebookIcon fontSize="small" />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              className={classes.link}
            >
              <InstagramIcon fontSize="small" />
            </a>
          </div>

          {props.copyright && (
            <div className={`${classes.item} ${classes.left}`}>
              <Typography component="p">{props.copyright}</Typography>
            </div>
          )}
        </div>
      </Container>
    </Section>
  );
}

export default Footer;

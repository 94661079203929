import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Associate Software Engineer",
      answer:
        "Job Descpription goes here....",
    }
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              {/* <Typography>{item.answer}</Typography> */}
                <p> 
                We are looking for a passionate, ambitious fresh talent who is eager to learn the new cutting-edge technologies and apply them to solve challenging problems. 
                Below are minimum expectations from the candidate but not limited.
                    <ul>
                      <li>Should have technical hunger to learn new tech stack. </li>
                      <li>Flexibility to switch from one role to other, one domain to other</li>
                      <li>Understanding Web architecture and familiarity with web technologies like HTML5, CSS3 etc</li>
                      <li>Sound knowledge in programming principles. Not looking for any specific language. But Java/Javascript is nice to have.</li>
                      <li>Good knowledge on Relational databases. knowledge on nosql is nice to have.</li>
                      <li>Willingness to work as a individual contributor and meeting the deliverables promptly</li>
                    </ul>
                  
                  <a style={{textDecoration: 'none'}} href="https://apply.workable.com/tringam-software-pvt-litd/j/42B544A3EA/">Apply here!</a>
                </p>  
              

            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;

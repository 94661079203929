import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import contact from "./../util/contact.js";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles(theme => ({
  multilineColor:{
     background: "#ffffff"
  },
  locationContainer:{
    paddingLeft: "0"
 }
}));
function Contact(props) {
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    contact
      .submit(data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          {props.showNameField && (
            <Grid item={true} xs={12} md={6}>
              <TextField
                variant="outlined"
                type="text"
                label="Name"
                name="name"
                InputProps={{
                  classes: {
                      input: classes.multilineColor
                  }
                }}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your name",
                })}
              />
            </Grid>
          )}

          <Grid item={true} xs={12} md={props.showNameField ? 6 : 12}>
            <TextField
              variant="outlined"
              type="email"
              label="Email"
              name="email"
              InputProps={{
                classes: {
                    input: classes.multilineColor
                }
              }}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your email",
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Message"
              name="message"
              multiline={true}
              rows={5}
              InputProps={{
                classes: {
                  root: classes.multilineColor
                }
              }}
              error={errors.message ? true : false}
              helperText={errors.message && errors.message.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter a message",
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
            >
              {!pending && <span>{props.buttonText}</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>

      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
  

      <Container maxWidth="xl"

      classes={{
        root:  classes.locationContainer
      }}
      >
        <SectionHeader
          title="Location"
          subtitle=""
          size={4}
          textAlign="left"
        />
      <Typography variant="subtitle1" className={classes.subtitle}>
      Plot no 9, Flat no 301, Sree Kubera Homes,
      </Typography>

      <Typography variant="subtitle1" className={classes.subtitle}>
      Andhra kesari Nagar, Road No 1,
      </Typography>

      <Typography variant="subtitle1" className={classes.subtitle}>
      Hyderabad, Telangana, 500070
      </Typography>
      </Container>

     
  

    </>
  );
}

export default Contact;
